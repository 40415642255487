import { v4 as uuidv4 } from 'uuid';
import Card from '../components/elements/Card/Card';
import Table from '../components/elements/Table/Table';
import { cardFactionLookup, cardTargetLookup, cardTypeLookup } from '../constants/constants';
import CardsCubit from '../state/blocs/CardsCubit';
import { useBloc } from '../state/state';

const CardView = () => {
    const [cards] = useBloc(CardsCubit);

    if (cards.json === null || Object.keys(cards.json).length === 0) {
        return <></>;
    }

    const renderTable = () => {
        return (
            <>
                <tr>
                    <td>
                        <p>
                            <b>{cards.json.id || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/cards.png'} key={uuidv4()} alt="" />
                    </td>
                    <td />
                    <td />
                    <td />
                    <td>
                        <p>
                            <b>{cards.json.userId || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/user.png'} key={uuidv4()} alt="" />
                    </td>
                </tr>
                <tr>
                    <td colSpan={5}>
                        {Array(cards.json.stars || 0)
                            .fill(0)
                            .map((x: any) => (
                                <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={`${'../icons/star.png'}`} key={uuidv4()} alt="" />
                            ))}
                        {Array(5 - (cards.json.stars || 0))
                            .fill(0)
                            .map((x: any) => (
                                <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={`${'../icons/no_star.png'}`} key={uuidv4()} alt="" />
                            ))}
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        {cards.json.leftWeapon && (
                            <img
                                style={{ maxWidth: '48px', maxHeight: '390px' }}
                                src={`${'./icons/weapons/' + (cards.json.leftWeapon || 0) + '_' + (cards.json.leftOffsetWeapon || 0) + '.png'}`}
                                key={uuidv4()}
                                alt=""
                            />
                        )}
                    </td>
                    <td>
                        {cards.json.hero && (
                            <img
                                style={{ maxWidth: '256px', maxHeight: '256px' }}
                                src={`${'./icons/cards/' + (cards.json.hero || 0) + '_' + (cards.json.offSetHero || 0) + '.png'}`}
                                key={uuidv4()}
                                alt=""
                            />
                        )}
                    </td>
                    <td colSpan={2}>
                        {cards.json.rightWeapon && (
                            <img
                                style={{ maxWidth: '48px', maxHeight: '390px' }}
                                src={`${'./icons/weapons/' + (cards.json.rightWeapon || 0) + '_' + (cards.json.rightOffsetWeapon || 0) + '.png'}`}
                                key={uuidv4()}
                                alt=""
                            />
                        )}
                    </td>
                </tr>
                <tr>
                    <td />
                    <td>
                        <p>
                            <b>{cardTargetLookup[cards.json.target || 0].label}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/target.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{cardTypeLookup[cards.json.type || 0].label}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/type.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{cardFactionLookup[cards.json.faction || 0].label}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/faction.png'} key={uuidv4()} alt="" />
                    </td>
                </tr>
                <tr>
                    <td colSpan={2}>
                        <p>
                            <b>{cards.json.damageAtLevel || [0, 0, 0, 0, 0]}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/damage.png'} key={uuidv4()} alt="" />
                    </td>
                    <td />
                    <td colSpan={2}>
                        <p>
                            <b>{cards.json.attackIntervalAtLevel || [0.0, 0.0, 0.0, 0.0, 0.0]}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/speed.png'} key={uuidv4()} alt="" />
                    </td>
                </tr>
            </>
        );
    };

    return (
        <Card color={cards.json.bgHex}>
            <Table noBorder noSpace thead={[]} tbody={renderTable()} />
        </Card>
    );
};

export default CardView;
