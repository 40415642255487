import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../components/elements/Card/Card';
import Input from '../components/elements/Input/Input';
import Button from '../components/elements/Button/Button';
import SaveDataCubit from '../state/blocs/SaveDataCubit';
import { useBloc } from '../state/state';
import { toast } from 'react-toastify';

const SearchByUserIdForm = () => {
    const { t } = useTranslation();

    const [saveData, { findUser, setUserId }] = useBloc(SaveDataCubit);

    const clickFind = async () => {
        if (saveData.cardId.length <= 3) {
            toast.warn('User id is empty');
            return;
        }

        findUser(saveData.cardId);
    };

    return (
        <Card title={t('home.find-by-user-id')} icon={'search'}>
            77C9BB8E C6383F7A
            <Input
                id={'value'}
                type={'text'}
                name={'userId'}
                value={saveData.cardId}
                placeholder={'example@user.com / ######'}
                min={0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserId(e.target.value)}
                isInvalid={saveData.cardId.length <= 3}
                isInvalidHelp={t('input.valid-value')}
                required={true}
            />
            <div className="grid">
                <Button color="primary" onClick={clickFind} label={t('button.search')} disabled={saveData.cardId.length <= 3} />
            </div>
        </Card>
    );
};

export default SearchByUserIdForm;
