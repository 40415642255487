import { ComponentProps, MouseEventHandler } from 'react';

interface IProps extends ComponentProps<any> {
    type?: 'button' | 'submit' | 'reset';
    color?: 'none' | 'primary' | 'secondary' | 'tercinary';
    label?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
    disabled?: boolean;
    s?: boolean;
    m?: boolean;
    l?: boolean;
    e?: boolean;
    responsive?: boolean;
    icon?: string;
}

const Button = (props: IProps) => {
    const { type, color, label, onClick, disabled, s, m, l, e, responsive, icon } = props;

    return (
        <button
            type={type}
            className={`${s ? 'small' : ''} ${m ? 'medium' : ''} ${l ? 'large' : ''} ${e ? 'extra' : ''} ${responsive ? 'responsive' : ''} ${color}`}
            onClick={onClick}
            disabled={disabled || false}
        >
            {icon && <i>{icon}</i>}
            <span>{label}</span>
        </button>
    );
};

Button.defaultProps = {
    type: 'button',
    color: 'primary',
    label: 'Button',
    onClick: undefined,
    disabled: false,
    s: false,
    m: false,
    l: false,
    e: false,
    responsive: false,
    icon: undefined,
};

export default Button;
