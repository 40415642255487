import { Cubit } from 'blac';

export default class AppStateCubit extends Cubit<{ style: 'light' | 'dark' }> {
    constructor() {
        super({ style: 'dark' });
    }

    public readonly setStyle = async (value: 'light' | 'dark') => {
        this.emit({ ...this.state, style: value });
    };
}
