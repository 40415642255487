import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Card from '../components/elements/Card/Card';
import Input from '../components/elements/Input/Input';
import Button from '../components/elements/Button/Button';
import { useBloc } from '../state/state';
import WeeklyRewardsCubit from '../state/blocs/WeeklyRewardsCubit';
import Select from '../components/elements/Select/Select';
import { weeklyRewardsLookup, weeklyRewardsOptions } from '../constants/constants';
import styled from '@emotion/styled';

const Img = styled.img`
    max-width: 48px;
    max-height: 48px;
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
`;

const AddWeeklyRewardForm = () => {
    const { t } = useTranslation();

    const [, { getAll, createWeeklyRewards }] = useBloc(WeeklyRewardsCubit);
    const [selectValue, setSelectValue] = useState({
        WeekNumber: '0',
        DayReward1: 0,
        DayReward1Amount: 0,
        DayReward2: 0,
        DayReward2Amount: 0,
        DayReward3: 0,
        DayReward3Amount: 0,
        DayReward4: 0,
        DayReward4Amount: 0,
        DayReward5: 0,
        DayReward5Amount: 0,
        DayReward6: 0,
        DayReward6Amount: 0,
        DayReward7: 0,
        DayReward7Amount: 0,
    });

    const handleChange = (event: any) => {
        setSelectValue({ ...selectValue, [event.target.id]: event.target.value });
    };

    const saveWeeklyRewards = () => {
        const data = [
            {
                type: Number(selectValue.DayReward1),
                amount: Number(selectValue.DayReward1Amount),
            },
            {
                type: Number(selectValue.DayReward2),
                amount: Number(selectValue.DayReward2Amount),
            },
            {
                type: Number(selectValue.DayReward3),
                amount: Number(selectValue.DayReward3Amount),
            },
            {
                type: Number(selectValue.DayReward4),
                amount: Number(selectValue.DayReward4Amount),
            },
            {
                type: Number(selectValue.DayReward5),
                amount: Number(selectValue.DayReward5Amount),
            },
            {
                type: Number(selectValue.DayReward6),
                amount: Number(selectValue.DayReward6Amount),
            },
            {
                type: Number(selectValue.DayReward7),
                amount: Number(selectValue.DayReward7Amount),
            },
        ];

        const payload = {
            weekNumber: selectValue.WeekNumber,
            rewards: data,
        };

        createWeeklyRewards(selectValue.WeekNumber, payload)
            .then(() => {
                console.log('Created new item successfully!');
                getAll();
            })
            .catch((e) => {
                console.log(e);
            });
    };

    return (
        <Card title={t('home.add-new-weekly-reward')} icon={'Add'}>
            <div className="grid">
                <div className="s12 m6 l4">
                    <Input id={'WeekNumber'} type={'string'} label={t('rewards.week-number')} value={selectValue.WeekNumber} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="grid">
                <div className="s2">
                    <Img src={weeklyRewardsLookup[selectValue.DayReward1].icon} alt="" />
                </div>
                <div className="s7">
                    <Select
                        id={'DayReward1'}
                        type={'number'}
                        label={t('rewards.day-reward') + ' 1'}
                        value={selectValue.DayReward1}
                        onChange={handleChange}
                        required={true}
                        options={weeklyRewardsOptions}
                    />
                </div>
                <div className="s3">
                    <Input id={'DayReward1Amount'} type={'number'} label={t('rewards.amount')} value={selectValue.DayReward1Amount} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="grid">
                <div className="s2">
                    <Img src={weeklyRewardsLookup[selectValue.DayReward2].icon} alt="" />
                </div>
                <div className="s7">
                    <Select
                        id={'DayReward2'}
                        type={'number'}
                        label={t('rewards.day-reward') + ' 2'}
                        value={selectValue.DayReward2}
                        onChange={handleChange}
                        required={true}
                        options={weeklyRewardsOptions}
                    />
                </div>
                <div className="s3">
                    <Input id={'DayReward2Amount'} type={'number'} label={t('rewards.amount')} value={selectValue.DayReward2Amount} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="grid">
                <div className="s2">
                    <Img src={weeklyRewardsLookup[selectValue.DayReward3].icon} alt="" />
                </div>
                <div className="s7">
                    <Select
                        id={'DayReward3'}
                        type={'number'}
                        label={t('rewards.day-reward') + ' 3'}
                        value={selectValue.DayReward3}
                        onChange={handleChange}
                        required={true}
                        options={weeklyRewardsOptions}
                    />
                </div>
                <div className="s3">
                    <Input id={'DayReward3Amount'} type={'number'} label={t('rewards.amount')} value={selectValue.DayReward3Amount} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="grid">
                <div className="s2">
                    <Img src={weeklyRewardsLookup[selectValue.DayReward4].icon} alt="" />
                </div>
                <div className="s7">
                    <Select
                        id={'DayReward4'}
                        type={'number'}
                        label={t('rewards.day-reward') + ' 4'}
                        value={selectValue.DayReward4}
                        onChange={handleChange}
                        required={true}
                        options={weeklyRewardsOptions}
                    />
                </div>
                <div className="s3">
                    <Input id={'DayReward4Amount'} type={'number'} label={t('rewards.amount')} value={selectValue.DayReward4Amount} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="grid">
                <div className="s2">
                    <Img src={weeklyRewardsLookup[selectValue.DayReward5].icon} alt="" />
                </div>
                <div className="s7">
                    <Select
                        id={'DayReward5'}
                        type={'number'}
                        label={t('rewards.day-reward') + ' 5'}
                        value={selectValue.DayReward5}
                        onChange={handleChange}
                        required={true}
                        options={weeklyRewardsOptions}
                    />
                </div>
                <div className="s3">
                    <Input id={'DayReward5Amount'} type={'number'} label={t('rewards.amount')} value={selectValue.DayReward5Amount} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="grid">
                <div className="s2">
                    <Img src={weeklyRewardsLookup[selectValue.DayReward6].icon} alt="" />
                </div>
                <div className="s7">
                    <Select
                        id={'DayReward6'}
                        type={'number'}
                        label={t('rewards.day-reward') + ' 6'}
                        value={selectValue.DayReward6}
                        onChange={handleChange}
                        required={true}
                        options={weeklyRewardsOptions}
                    />
                </div>
                <div className="s3">
                    <Input id={'DayReward6Amount'} type={'number'} label={t('rewards.amount')} value={selectValue.DayReward6Amount} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="grid">
                <div className="s2">
                    <Img src={weeklyRewardsLookup[selectValue.DayReward7].icon} alt="" />
                </div>
                <div className="s7">
                    <Select
                        id={'DayReward7'}
                        type={'number'}
                        label={t('rewards.day-reward') + ' 7'}
                        value={selectValue.DayReward7}
                        onChange={handleChange}
                        required={true}
                        options={weeklyRewardsOptions}
                    />
                </div>
                <div className="s3">
                    <Input id={'DayReward7Amount'} type={'number'} label={t('rewards.amount')} value={selectValue.DayReward7Amount} onChange={handleChange} required={true} />
                </div>
            </div>
            <div className="space"></div>
            <Button type={'button'} color={'secondary'} onClick={saveWeeklyRewards} label={t('button.save')}></Button>
        </Card>
    );
};

export default AddWeeklyRewardForm;
