import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import logo from '../../assets/img/logo.png';
import { UserContext } from '../../context/UserContext';
import Card from '../../components/elements/Card/Card';
import { toast } from 'react-toastify';
import StyledFirebaseAuth from '../../components/elements/StyledFirebaseAuth/StyledFirebaseAuth';

const Wrapper = styled.div`
    margin: 0 auto;
    display: flex;
    height: 100vh;
    flex-direction: column;
    overflow: hidden;
`;

const MainContent = styled.main`
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 100vh;
    justify-content: center;

    position: relative;
    flex: 1;
    height: 100vh;
`;

const Login = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [rememberMe, setRememberMe] = React.useState<number>(0);

    // Context
    const [, setUser] = React.useContext(UserContext);

    // --------------------------------------------
    // On State Change Auto Actions
    // --------------------------------------------

    useEffect(() => {
        setUser(null);

        firebase.auth().onAuthStateChanged((user) => {
            if (user) {
                setUser(firebase.auth().currentUser);
                navigate('/');
            }
        });
        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // --------------------------------------------
    // UI Interactions
    // --------------------------------------------

    const onLoginAction = (res: any) => {
        if (!firebase.auth().currentUser) {
            toast.error(res);
            console.log('failed:', res);
            return false;
        }

        // TODO Move to firebase?
        if (firebase.auth().currentUser!.email !== 'zanoski.marko@gmail.com') {
            toast.error('User not allowed');
            setUser(null);
            return false;
        } else {
            firebase.auth().setPersistence(rememberMe === 1 ? 'local' : 'session');
            setUser(firebase.auth().currentUser);
            navigate('/');
            return true;
        }
    };

    // Configure FirebaseUI.
    const uiConfig = {
        // Popup signin flow rather than redirect flow.
        signInFlow: 'popup',
        // We will display Google and Facebook as auth providers.
        signInOptions: [firebase.auth.GoogleAuthProvider.PROVIDER_ID],
        callbacks: {
            // Avoid redirects after sign-in.
            signInSuccessWithAuthResult: onLoginAction,
        },
    };

    // --------------------------------------------
    // UI Render
    // --------------------------------------------

    return (
        <Wrapper className="wrapper">
            <MainContent>
                <div style={{ minWidth: '360px', width: '100%', maxWidth: '360px' }}>
                    <div
                        style={{
                            textAlign: 'center',
                        }}
                    >
                        <img src={logo} alt="Logo" style={{ maxHeight: '128px' }} />
                    </div>
                    <Card noTitle>
                        <form>
                            <p style={{ textAlign: 'center' }}>{t('login.title')}</p>
                            <div className="center-align">
                                <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} uiCallback={(e) => onLoginAction(e)} />
                            </div>
                            <div className="field label" style={{ marginBottom: 0 }}>
                                <label className="checkbox">
                                    <input
                                        type="checkbox"
                                        id="remember"
                                        value={rememberMe}
                                        onClick={() => {
                                            setRememberMe(rememberMe === 0 ? 1 : 0);
                                        }}
                                    />
                                    <span>{t('login.remember-me')}</span>
                                </label>
                            </div>
                        </form>
                    </Card>
                </div>
            </MainContent>
        </Wrapper>
    );
};

export default Login;
