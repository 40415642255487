import CardView from '../../ui/CardView';
import JsonCardEditor from '../../ui/JsonCardEditor';
import SearchByCardIdForm from '../../ui/SearchByCardId';

const WeeklyRewards = () => {
    // --------------------------------------------
    // UI Render
    // --------------------------------------------
    return (
        <div className="grid">
            <div className="s12 m6">
                <SearchByCardIdForm />
                <CardView />
            </div>
            <div className="s12 m6">
                <JsonCardEditor />
            </div>
        </div>
    );
};

export default WeeklyRewards;
