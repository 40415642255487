/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';
import i18n from '../../translations/i18n';
import firebase from 'firebase/compat/app';
import AppStateCubit from '../../state/blocs/AppStateCubit';
import { useBloc } from '../../state/state';

const Sidebar = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();

    const [modalStateSettings, setModalStateSettings] = React.useState<boolean>(false);

    const [appState, { setStyle }] = useBloc(AppStateCubit);

    const version = packageJson.version;

    const clickLogout = () => {
        firebase
            .auth()
            .signOut()
            .then(() => {
                console.log('Signed Out');
                localStorage.clear();
                navigate('/login');
            })
            .catch((e) => {
                console.error('Sign Out Error', e);
            });
    };

    const clickIssue = () => {
        window.open('https://gitlab.com/farm-royale/farm-royale-web-admin/-/issues', '_blank');
    };

    const clickEnglish = async () => {
        await i18n.changeLanguage('en', (err) => {
            if (err) {
                console.error('Issue switching lang to en', err);
                return;
            }
            moment.locale('en');
            return true;
        });

        setModalStateSettings(false);
    };

    const clickStyle = async () => {
        if (appState.style === 'light') {
            setStyle('dark');
            document.body.classList.add('dark');
            document.body.classList.remove('light');
        } else {
            setStyle('light');
            document.body.classList.add('light');
            document.body.classList.remove('dark');
        }
    };

    const clickWeeklyRewards = async () => {
        navigate('/weekly-rewards');
    };

    const clickDashboard = async () => {
        navigate('/');
    };

    const clickCards = async () => {
        navigate('/cards');
    };

    return (
        <>
            <nav className="left m l">
                <a>
                    <strong style={{ color: 'var(--primary)' }}>FRAG</strong>
                </a>
                <a className={`${location.pathname === '/' ? 'active' : ''}`} onClick={clickDashboard}>
                    <i>Person</i>
                    <div>Users</div>
                </a>
                <a className={`${location.pathname === '/cards' ? 'active' : ''}`} onClick={clickCards}>
                    <i>Sim_Card</i>
                    <div>Cards</div>
                </a>
                <a className={`${location.pathname === '/weekly-rewards' ? 'active' : ''}`} onClick={clickWeeklyRewards}>
                    <i>Redeem</i>
                    <div>Rewards</div>
                </a>
                <a className={`${modalStateSettings === true ? 'active' : ''}`} onClick={() => setModalStateSettings(!modalStateSettings)}>
                    <i>Settings</i>
                    <div>Settings</div>
                </a>
                <a onClick={clickLogout}>
                    <i>Logout</i>
                    <div>Logout</div>
                </a>
            </nav>
            <nav className="bottom s">
                <a className={`${location.pathname === '/' ? 'active' : ''}`} onClick={clickDashboard}>
                    <i>Dashboard</i>
                </a>
                <a className={`${location.pathname === '/cards' ? 'active' : ''}`} onClick={clickCards}>
                    <i>Sim_Card</i>
                </a>
                <a className={`${location.pathname === '/weekly-rewards' ? 'active' : ''}`} onClick={clickWeeklyRewards}>
                    <i>Redeem</i>
                </a>
                <a className={`${modalStateSettings === true ? 'active' : ''}`} onClick={() => setModalStateSettings(!modalStateSettings)}>
                    <i>Settings</i>
                </a>
                <a onClick={clickLogout}>
                    <i>Logout</i>
                </a>
            </nav>
            <div className={`overlay ${modalStateSettings ? 'active' : ''}`} />
            {modalStateSettings && (
                <div className="modal active" id="modal-settings">
                    <h5>{t('sidebar.settings')}</h5>
                    <h6>{t('sidebar.credits')}</h6>
                    <a href="https://www.flaticon.com" title="coupon icons" target="_blank" rel="noopener noreferrer">
                        {t('sidebar.credit-icons')}
                    </a>
                    <div className="space" />
                    <h6>{t('sidebar.select-your-language')}</h6>
                    <p>
                        <button onClick={clickEnglish}>{t('sidebar.english')}</button>
                    </p>
                    <div className="space" />
                    <p>
                        <button className="secondary">
                            <i>Conversion_Path</i>
                            &nbsp;{t('footer.version')} {version}
                        </button>
                        <button onClick={clickIssue}>
                            <i>Flag</i>
                            &nbsp;{t('sidebar.report')}
                        </button>
                        <button onClick={clickStyle}>
                            <i>{appState.style === 'light' ? 'Light_Mode' : 'Dark_Mode'}</i>
                            &nbsp;{t('sidebar.style')}
                        </button>
                    </p>
                    <div className="space" />
                    <nav>
                        <button className="secondary" data-target="modal-settings" onClick={() => setModalStateSettings(false)}>
                            {t('button.close')}
                        </button>
                    </nav>
                </div>
            )}
        </>
    );
};
export default Sidebar;
