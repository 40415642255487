import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

export const config = {
    apiKey: 'AIzaSyA94UKtPpX4ef6wNwI_4VsTtCiPeL4yBls',
    authDomain: 'farm-royale.firebaseapp.com',
    databaseURL: 'https://farm-royale-default-rtdb.europe-west1.firebasedatabase.app',
    projectId: 'farm-royale',
    storageBucket: 'farm-royale.appspot.com',
    messagingSenderId: '298867311181',
    appId: '1:298867311181:web:656b1a2420e5c9a1d19204',
};

firebase.initializeApp(config);

export default firebase.database();
