import { v4 as uuidv4 } from 'uuid';
import Card from '../components/elements/Card/Card';
import SaveDataCubit from '../state/blocs/SaveDataCubit';
import { useBloc } from '../state/state';
import Table from '../components/elements/Table/Table';
import { useTranslation } from 'react-i18next';

const GameStateView = () => {
    const { t } = useTranslation();
    const [saveData] = useBloc(SaveDataCubit);

    const renderTable = () => {
        return (
            <>
                <tr>
                    <td>
                        <p>
                            <b>{saveData.json.isTutorialCompleted || 0 ? 1 : 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/tutorial.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.adResourceReward || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/ad.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.openedPathBoxes || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/path.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.openedCardBoxes || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/card.png'} key={uuidv4()} alt="" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            <b>{saveData.json.popularity || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/popularity.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.gold || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/gold.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.ores || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/ores.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.seeds || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/seeds.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.wood || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/wood.png'} key={uuidv4()} alt="" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <p>
                            <b>{Number(saveData.json.settingMusic || 0).toFixed(2)}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/music.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.settingPush || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/notifications.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{Number(saveData.json.settingSfx || 0).toFixed(2)}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/sfx.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.settingVibration || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/vibration.png'} key={uuidv4()} alt="" />
                    </td>
                </tr>
                <tr>
                    <td>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/fusion.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.fusionPathCostGold || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/gold.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.fusionPathCostOres || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/ores.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.fusionPathCostSeeds || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/seeds.png'} key={uuidv4()} alt="" />
                    </td>
                    <td>
                        <p>
                            <b>{saveData.json.fusionPathCostWood || 0}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/wood.png'} key={uuidv4()} alt="" />
                    </td>
                </tr>
            </>
        );
    };

    return (
        <Card title={t('home.game-state')}>
            <Table thead={[]} tbody={renderTable()} />
        </Card>
    );
};

export default GameStateView;
