import React, { ComponentProps } from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Header {
    class?: string;
    label: string;
}

interface IProps extends ComponentProps<any> {
    label?: string;
    thead: Header[];
    tbody: any;
    border?: boolean;
    noBorder?: boolean;
    noSpace?: boolean;
}

const Table = (props: IProps) => {
    const { noBorder, border, noSpace } = props;
    const formulateHeader = () => {
        return props.thead.map((header) => {
            return <th key={uuidv4()}>{header.label}</th>;
        });
    };
    return (
        <div className="scroll">
            <table className={`${noBorder ? 'no-border' : ''} ${border ? 'border' : ''} ${noSpace ? 'no-space' : ''} center-align`}>
                <thead>
                    {props.label && (
                        <tr>
                            <th>{props.label}</th>
                        </tr>
                    )}
                    <tr>{formulateHeader()}</tr>
                </thead>
                <tbody>{props.tbody}</tbody>
            </table>
        </div>
    );
};

Table.defaultProps = {
    label: undefined,
    border: undefined,
    noBorder: undefined,
    noSpace: undefined,
    thead: [],
    tbody: [],
};

export default Table;
