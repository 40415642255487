import { v4 as uuidv4 } from 'uuid';
import Card from '../components/elements/Card/Card';
import SaveDataCubit from '../state/blocs/SaveDataCubit';
import { useBloc } from '../state/state';
import Table from '../components/elements/Table/Table';
import { useTranslation } from 'react-i18next';

const WeeklyRewardsView = () => {
    const { t } = useTranslation();
    const [saveData] = useBloc(SaveDataCubit);

    const renderTable = () => {
        const data = saveData.json.weeklyReward || [0, 0, 0, 0, 0, 0, 0];

        return (
            <tr key={uuidv4()}>
                {data.map((value: boolean) => (
                    <td key={uuidv4()}>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={`${value ? '../icons/clear.png' : '../icons/locked.png'}`} key={uuidv4()} alt="" />
                    </td>
                ))}
            </tr>
        );
    };

    return (
        <Card title={t('home.weekly-rewards')}>
            <Table thead={[]} tbody={renderTable()} />
        </Card>
    );
};

export default WeeklyRewardsView;
