import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import Main from './pages/Main/Main';
import packageJson from '../package.json';
import ContextCombiner from './context/ContextCombiner';
import Login from './pages/Login/Login';

Sentry.init({
    dsn: 'https://5cae6c0c6f0a426e84b6ff67fda367c6@o1397703.ingest.sentry.io/4503981210337280',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.25,
    sampleRate: 0.25,
    release: 'farm-royale-web-admin@' + packageJson.version,
    environment: process.env.REACT_APP_ENV,
    debug: false,
});

const App: React.FC = () => {

    if (process.env.REACT_APP_DEBUG !== 'true') {
        // disable right click
        document.addEventListener('contextmenu', function (event) {
            event.preventDefault();
        });

        document.onkeydown = function (e) {
            // disable F12 key
            if (e.keyCode === 123) {
                return false;
            }

            // disable I key
            if (e.ctrlKey && e.shiftKey && e.keyCode === 73) {
                return false;
            }

            // disable J key
            if (e.ctrlKey && e.shiftKey && e.keyCode === 74) {
                return false;
            }

            // disable U key
            if (e.ctrlKey && e.keyCode === 85) {
                return false;
            }
        };
    }

    return (
        <>
            <ToastContainer autoClose={3000} position={toast.POSITION.TOP_RIGHT} />

            <ContextCombiner>
                <Router>
                    <Routes>
                        <Route path="/login" element={<Login />} />
                        <Route path="*" element={<Main />} />
                    </Routes>
                </Router>
            </ContextCombiner>
        </>
    );
};

export default App;
