import Editor from '@monaco-editor/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Button from '../components/elements/Button/Button';
import Card from '../components/elements/Card/Card';
import AppStateCubit from '../state/blocs/AppStateCubit';
import SaveDataCubit from '../state/blocs/SaveDataCubit';
import { useBloc } from '../state/state';

const JsonUserEditor = () => {
    const { t } = useTranslation();
    const [saveData, { setJson, updateUser }] = useBloc(SaveDataCubit);
    const [appState] = useBloc(AppStateCubit);
    const [localJson, setLocalJson] = useState<string>('{}');
    const [saveJson, setJsonSave] = useState<object>({});

    useEffect(() => {
        setLocalJson(JSON.stringify(saveData.json, null, '\t'));
    }, [saveData]);

    const onEditorChange = (newVal: any) => {
        let toSave;

        try {
            toSave = JSON.parse(newVal);
        } catch (e) {
            return;
        }

        setJsonSave(toSave);
    };

    const clickPreview = async () => {
        if (saveData.cardId.length <= 3) {
            toast.warn('User id is empty');
            return;
        }

        setJson(saveJson);
    };

    const clickSave = async () => {
        if (saveData.cardId.length <= 3) {
            toast.warn('User id is empty');
            return;
        }

        updateUser(saveData.cardId, saveJson);
    };

    return (
        <Card noTitle>
            <Editor
                theme={appState.style === 'light' ? 'vs' : 'vs-dark'}
                onChange={onEditorChange}
                height="70vh"
                defaultLanguage="json"
                value={localJson}
                options={{
                    fontSize: 18,
                    folding: false,
                    lineNumbers: 'off',
                    formatOnPaste: true,
                    minimap: {
                        enabled: false,
                    },
                }}
            />
            <div className="grid">
                <div className="s3">
                    <Button color="primary" onClick={clickPreview} label={t('button.preview')} disabled={saveData.cardId.length <= 3} />
                </div>
                <div className="space" />
                <div className="s3">
                    <Button color="secondary" onClick={clickSave} label={t('button.save')} disabled={saveData.cardId.length <= 3} />
                </div>
            </div>
        </Card>
    );
};

export default JsonUserEditor;
