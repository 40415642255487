import { hydrateRoot, createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';

import 'beercss/dist/cdn/beer.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './translations/i18n';
import './overload-style.css';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = document.getElementById('root')!;

if (root && root.innerHTML !== '') {
    hydrateRoot(root, <App />);
} else {
    const rootRender = createRoot(root);
    rootRender.render(<App />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
