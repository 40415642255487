import { v4 as uuidv4 } from 'uuid';
import Card from '../components/elements/Card/Card';
import SaveDataCubit from '../state/blocs/SaveDataCubit';
import { useBloc } from '../state/state';
import Table from '../components/elements/Table/Table';
import { pathLevelColorLookup } from '../constants/constants';
import { useTranslation } from 'react-i18next';

const DeployedPathsView = () => {
    const { t } = useTranslation();
    const [saveData] = useBloc(SaveDataCubit);

    const renderTable = () => {
        const data = saveData.json.deployedPaths || [];

        let result: any = [];
        data.forEach((item: any) => {
            let resObj = result.find((resObj: any) => resObj.pathLevel === item.pathLevel && resObj.pathType === item.pathType);
            resObj ? resObj.count++ : result.push({ pathLevel: item.pathLevel, pathType: item.pathType, count: 1 });
        });

        return (
            <tr key={uuidv4()}>
                {result.map((x: any) => (
                    <td key={uuidv4()} style={{ backgroundColor: pathLevelColorLookup[x.pathLevel] }}>
                        <p>
                            <b>{x.count}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={`${'../icons/path_' + x.pathType + '.png'}`} key={uuidv4()} alt="" />
                    </td>
                ))}
            </tr>
        );
    };

    return (
        <Card title={t('home.deployed-paths')}>
            <Table thead={[]} tbody={renderTable()} />
        </Card>
    );
};

export default DeployedPathsView;
