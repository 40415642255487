import React, { useEffect } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import Sidebar from '../../components/Sidebar/Sidebar';
import Home from '../../views/Home/Home';
import WeeklyRewards from '../../views/Home/WeeklyRewards';
import Cards from '../../views/Home/Cards';

const Main = () => {
    const navigate = useNavigate();

    // Context
    const [user] = React.useContext(UserContext);

    useEffect(() => {
        async function forceLogin() {
            if (user === null) {
                navigate('/login');
                return;
            }
        }
        forceLogin();
    }, [user, navigate]);

    return (
        <>
            <Sidebar />
            <main className="responsive">
                <Routes>
                    <Route path="/weekly-rewards" element={<WeeklyRewards />} />
                    <Route path="/cards" element={<Cards />} />
                    <Route path="/" element={<Home />} />
                </Routes>
            </main>
        </>
    );
};

export default Main;
