import React, { ComponentProps } from 'react';

interface IProps extends ComponentProps<any> {
    title?: string;
    icon?: string;
    loading?: boolean;
    noTitle?: boolean;
}

const Card = (props: IProps) => {
    const { title, icon, loading, noTitle, color } = props;

    return (
        <>
            {loading && (
                <article>
                    <p className="center-align">
                        <p className="loader large" aria-busy="true"></p>
                    </p>
                </article>
            )}
            {!loading && (
                <article style={{ backgroundColor: color }}>
                    {!noTitle && (
                        <h5>
                            {icon && (
                                <span>
                                    <i>{icon}</i> &nbsp;
                                </span>
                            )}
                            {title}
                        </h5>
                    )}
                    {props.children}
                </article>
            )}
        </>
    );
};

Card.defaultProps = {
    color: undefined,
    title: undefined,
    icon: undefined,
    loading: undefined,
};

export default Card;
