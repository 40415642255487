import React, { useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import Card from '../components/elements/Card/Card';
import { useBloc } from '../state/state';
import Table from '../components/elements/Table/Table';
import WeeklyRewardsCubit from '../state/blocs/WeeklyRewardsCubit';
import Button from '../components/elements/Button/Button';
import { weeklyRewardsLookup } from '../constants/constants';
import { IWeeklyReward } from '../interfaces/interfaces';

const OverviewWeeklyRewards = () => {
    const { t } = useTranslation();

    const [weeklyRewards, { setEndAt, getAll, deleteWeeklyRewards }] = useBloc(WeeklyRewardsCubit);

    useEffect(() => {
        getAll();

        // TODO
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const listWeeklyRewardsHeader = new Array<{ label: string }>();
    listWeeklyRewardsHeader.push({ label: t('header.weekNumber') });
    listWeeklyRewardsHeader.push({ label: t('header.day') + ' 1' });
    listWeeklyRewardsHeader.push({ label: t('header.day') + ' 2' });
    listWeeklyRewardsHeader.push({ label: t('header.day') + ' 3' });
    listWeeklyRewardsHeader.push({ label: t('header.day') + ' 4' });
    listWeeklyRewardsHeader.push({ label: t('header.day') + ' 5' });
    listWeeklyRewardsHeader.push({ label: t('header.day') + ' 6' });
    listWeeklyRewardsHeader.push({ label: t('header.day') + ' 7' });
    listWeeklyRewardsHeader.push({ label: t('header.action') });

    const listWeeklyRewards = () => {
        return (
            weeklyRewards.state.length > 0 &&
            weeklyRewards.state.reverse() &&
            weeklyRewards.state.map(function (weeklyReward: IWeeklyReward) {
                return (
                    <tr key={uuidv4()}>
                        <td>{weeklyReward.weekNumber}</td>
                        <td>
                            {weeklyReward.rewards[0].amount}&nbsp;&#120;&nbsp;
                            <img src={weeklyRewardsLookup[weeklyReward.rewards[0].type].icon} alt="" />
                        </td>
                        <td>
                            {weeklyReward.rewards[1].amount}&nbsp;&#120;&nbsp;
                            <img src={weeklyRewardsLookup[weeklyReward.rewards[1].type].icon} alt="" />
                        </td>
                        <td>
                            {weeklyReward.rewards[2].amount}&nbsp;&#120;&nbsp;
                            <img src={weeklyRewardsLookup[weeklyReward.rewards[2].type].icon} alt="" />
                        </td>
                        <td>
                            {weeklyReward.rewards[3].amount}&nbsp;&#120;&nbsp;
                            <img src={weeklyRewardsLookup[weeklyReward.rewards[3].type].icon} alt="" />
                        </td>
                        <td>
                            {weeklyReward.rewards[4].amount}&nbsp;&#120;&nbsp;
                            <img src={weeklyRewardsLookup[weeklyReward.rewards[4].type].icon} alt="" />
                        </td>
                        <td>
                            {weeklyReward.rewards[5].amount}&nbsp;&#120;&nbsp;
                            <img src={weeklyRewardsLookup[weeklyReward.rewards[5].type].icon} alt="" />
                        </td>
                        <td>
                            {weeklyReward.rewards[6].amount}&nbsp;&#120;&nbsp;
                            <img src={weeklyRewardsLookup[weeklyReward.rewards[6].type].icon} alt="" />
                        </td>
                        <td>
                            <Button
                                s
                                type={'button'}
                                color={'none'}
                                onClick={() => {
                                    deleteWeeklyRewards(weeklyReward.weekNumber)
                                        .then(() => {
                                            console.log('Created new item successfully!');
                                            getAll();
                                        })
                                        .catch((e) => {
                                            console.log(e);
                                        });
                                }}
                                label={t('button.delete')}
                            />
                        </td>
                    </tr>
                );
            })
        );
    };

    return (
        <Card title={t('home.overview')} icon={'list'}>
            <div className="grid">
            <div className="s12 m3"></div>
                <div className="s4 m2 no-padding middle-align center-align">
                    <Button
                        responsive
                        color={'secondary'}
                        label={t('button.previous')}
                        onClick={() => {
                            const tempEndAt = weeklyRewards.endAt - 5;
                            setEndAt(tempEndAt);
                            getAll(tempEndAt);
                        }}
                    />
                </div>
                <div className="s4 m2 border medium no-padding middle-align center-align">{weeklyRewards.endAt}</div>
                <div className="s4 m2 no-padding middle-align center-align">
                    <Button
                        responsive
                        color={'tercinary'}
                        label={t('button.next')}
                        onClick={() => {
                            const tempEndAt = weeklyRewards.endAt + 5;
                            setEndAt(tempEndAt);
                            getAll(tempEndAt);
                        }}
                    />
                </div>
            </div>

            <Table thead={listWeeklyRewardsHeader} tbody={listWeeklyRewards()} />
        </Card>
    );
};

export default OverviewWeeklyRewards;
