import { v4 as uuidv4 } from 'uuid';
import Card from '../components/elements/Card/Card';
import SaveDataCubit from '../state/blocs/SaveDataCubit';
import { useBloc } from '../state/state';
import Table from '../components/elements/Table/Table';
import { useTranslation } from 'react-i18next';

const UserView = () => {
    const { t } = useTranslation();
    const [saveData] = useBloc(SaveDataCubit);

    const acceptedTocUtc = new Date(0);
    const lastDateRedeemed = new Date(0);

    const renderTable = () => {
        acceptedTocUtc.setSeconds(saveData.json.user && saveData.json.user.acceptedTocUtc ? saveData.json.user.acceptedTocUtc : 0);
        lastDateRedeemed.setSeconds(saveData.json.user && saveData.json.user.lastDateRedeemedDaily ? saveData.json.user.lastDateRedeemedDaily : 0);

        return (
            <tr>
                <td>
                    <p>
                        <b>{saveData.json.user && saveData.json.user.acceptedTocUtc ? acceptedTocUtc.toISOString() : 0}</b>
                    </p>
                    <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/terms.png'} key={uuidv4()} alt="" />
                </td>
                <td>
                    <p>
                        <b>{saveData.json.user && saveData.json.user.daysRedeemedInRow ? saveData.json.user.daysRedeemedInRow : 0}</b>
                    </p>
                    <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/daily.png'} key={uuidv4()} alt="" />
                </td>
                <td>
                    <p>
                        <b>{saveData.json.user && saveData.json.user.lastDateRedeemedDaily ? lastDateRedeemed.toISOString() : 0}</b>
                    </p>
                    <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={'./icons/timetable.png'} key={uuidv4()} alt="" />
                </td>
            </tr>
        );
    };

    return (
        <Card title={t('home.user')}>
            <Table thead={[]} tbody={renderTable()} />
        </Card>
    );
};

export default UserView;
