import { Cubit } from 'blac';
import { toast } from 'react-toastify';
import { ISaveDataCubit } from '../../interfaces/interfaces';
import i18n from '../../translations/i18n';
import firebase from '../../utils/firebase';

const db = firebase.ref('/SaveData');

export default class SaveDataCubit extends Cubit<ISaveDataCubit> {
    constructor() {
        super({ cardId: '', json: {} });
    }

    public readonly findUser = async (userId = this.state.cardId) => {
        let response;
        await db
            .orderByChild('user/id')
            .equalTo(userId)
            .once('value', function (snapshot: any) {
                snapshot.forEach(function (childSnapshot: any) {
                    response = childSnapshot.val();
                });
            });

        if (!response) {
            this.emit({ cardId: this.state.cardId, json: {} });
        } else {
            this.emit({ cardId: this.state.cardId, json: response });
        }
    };

    public readonly setUserId = async (value: string) => {
        this.emit({ cardId: value, json: this.state.json });
    };

    public readonly setJson = async (value: object) => {
        this.emit({ cardId: this.state.cardId, json: value });
    };

    public readonly updateUser = async (key = this.state.cardId, value = this.state.json) => {
        await db
            .child(key)
            .set(value)
            .then((e: any) => {
                toast.success(i18n.t(`${e}`) as string);
            })
            .catch((e: any) => {
                toast.error(e);
                console.log(e);
            });
    };
}
