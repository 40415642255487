import React from 'react';
import AddWeeklyRewardForm from '../../ui/AddWeeklyRewardForm';

import OverviewWeeklyRewards from '../../ui/OverviewWeeklyRewards';

const Cards = () => {
    // --------------------------------------------
    // UI Render
    // --------------------------------------------
    return (
        <>
            <div className="grid">
                <div className="s12">
                    <OverviewWeeklyRewards />
                </div>
            </div>
            <div className="space"></div>
            <div className="s12">
                <AddWeeklyRewardForm />
            </div>
        </>
    );
};

export default Cards;
