import { ComponentProps } from 'react';
import { v4 as uuidv4 } from 'uuid';
import Card from '../components/elements/Card/Card';
import Table from '../components/elements/Table/Table';
import { pathLevelColorLookup } from '../constants/constants';

interface IProps extends ComponentProps<any> {
    title: string;
    data: [];
}

const RoadStackView = (props: IProps) => {
    const { title, data } = props;

    const renderTable = () => {
        return (
            <tr key={uuidv4()}>
                {data.map((x: any) => (
                    <td key={uuidv4()} style={{ backgroundColor: pathLevelColorLookup[x.pathLevel] }}>
                        <p>
                            <b>{x.amount}</b>
                        </p>
                        <img style={{ maxWidth: '48px', maxHeight: '48px' }} src={`${'../icons/path_' + x.pathType + '.png'}`} key={uuidv4()} alt="" />
                    </td>
                ))}
            </tr>
        );
    };

    return (
        <Card title={title}>
            <Table thead={[]} tbody={renderTable()} />
        </Card>
    );
};

RoadStackView.defaultProps = {
    title: 'todo',
    data: [],
};

export default RoadStackView;
