import React from 'react';
import { useTranslation } from 'react-i18next';
import Card from '../components/elements/Card/Card';
import Input from '../components/elements/Input/Input';
import Button from '../components/elements/Button/Button';
import { useBloc } from '../state/state';
import { toast } from 'react-toastify';
import CardsCubit from '../state/blocs/CardsCubit';

const SearchByCardIdForm = () => {
    const { t } = useTranslation();

    const [cards, { findCard, setCardId }] = useBloc(CardsCubit);

    const clickFind = async () => {
        if (cards.cardId.length <= 3) {
            toast.warn('User id is empty');
            return;
        }

        findCard(cards.cardId);
    };

    return (
        <Card title={t('home.find-by-card-id')} icon={'search'}>
            <Input
                id={'value'}
                type={'text'}
                name={'userId'}
                value={cards.cardId}
                placeholder={'######'}
                min={0}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCardId(e.target.value)}
                isInvalid={cards.cardId.length <= 3}
                isInvalidHelp={t('input.valid-value')}
                required={true}
            />
            <div className="grid">
                <Button color="primary" onClick={clickFind} label={t('button.search')} disabled={cards.cardId.length <= 3} />
            </div>
        </Card>
    );
};

export default SearchByCardIdForm;
