import { IOptions } from '../interfaces/interfaces';

export const DATE_FORMAT = 'YYYY-MM-DD';

export const CHART_DATE_FORMAT = 'MMM YYYY';

export const pathLevelColorLookup: any = {
    1: '#ED8A3F80',
    2: '#D6CA5880',
    3: '#8B919580',
    4: '#4F618280',
};

export const cardFactionLookup: any = {
    0: {
        value: 0,
        label: 'DESERT',
        icon: '../icons/type_0.png',
    },
    1: {
        value: 1,
        label: 'FOREST',
        icon: '../icons/type_1.png',
    },
    2: {
        value: 2,
        label: 'AQUATIC',
        icon: '../icons/type_2.png',
    },
    3: {
        value: 3,
        label: 'TUNDRA',
        icon: '../icons/type_2.png',
    },
    4: {
        value: 3,
        label: 'GRASSLAND',
        icon: '../icons/type_2.png',
    },
    5: {
        value: 3,
        label: 'SHRUBLAND',
        icon: '../icons/type_2.png',
    }
};

export const cardTypeLookup: any = {
    0: {
        value: 0,
        label: 'DAMAGE',
        icon: '../icons/type_0.png',
    },
    1: {
        value: 1,
        label: 'DEBUFF',
        icon: '../icons/type_1.png',
    },
    2: {
        value: 2,
        label: 'SUMMON',
        icon: '../icons/type_2.png',
    },
    3: {
        value: 3,
        label: 'SUPPORT',
        icon: '../icons/type_3.png',
    },
    4: {
        value: 4,
        label: 'SPECIAL',
        icon: '../icons/type_4.png',
    },
};

export const cardTargetLookup: any = {
    0: {
        value: 0,
        label: 'FIRST',
        icon: '../icons/target_0.png',
    },
    1: {
        value: 1,
        label: 'RANDOM',
        icon: '../icons/target_1.png',
    },
    2: {
        value: 2,
        label: 'NONE',
        icon: '../icons/target_2.png',
    },
    3: {
        value: 3,
        label: 'MAX_HEALTH',
        icon: '../icons/target_3.png',
    },
};

export const cardWeaponLookup: any = {
    0: {
        value: 0,
        label: 'POTATO',
        icon: '../icons/weapon_0.png',
    },
    1: {
        value: 1,
        label: 'CARROT',
        icon: '../icons/weapon_1.png',
    },
    2: {
        value: 2,
        label: 'ONION',
        icon: '../icons/weapon_2.png',
    },
    3: {
        value: 3,
        label: 'PEPPER',
        icon: '../icons/weapon_3.png',
    },
    4: {
        value: 4,
        label: 'CORN',
        icon: '../icons/weapon_4.png',
    },
    5: {
        value: 5,
        label: 'LEEK',
        icon: '../icons/weapon_5.png',
    },
};

export const cardHeroLookup: any = {
    0: {
        value: 0,
        label: 'WHEAT',
        icon: '../icons/hero_0.png',
    },
    1: {
        value: 1,
        label: 'SUNFLOWER',
        icon: '../icons/hero_1.png',
    },
    2: {
        value: 2,
        label: 'TROPICAL',
        icon: '../icons/hero_2.png',
    },
    3: {
        value: 3,
        label: 'FIRE',
        icon: '../icons/hero_3.png',
    },
    4: {
        value: 4,
        label: 'PUMPKIN',
        icon: '../icons/hero_4.png',
    },
    5: {
        value: 5,
        label: 'LEAF',
        icon: '../icons/hero_5.png',
    },
    6: {
        value: 6,
        label: 'TREE_LEAF',
        icon: '../icons/hero_6.png',
    },
    7: {
        value: 7,
        label: 'TWO_LEAF',
        icon: '../icons/hero_7.png',
    },
    8: {
        value: 8,
        label: 'RED_FLOWER',
        icon: '../icons/hero_8.png',
    },
    9: {
        value: 9,
        label: 'SALAD',
        icon: '../icons/hero_9.png',
    },
};

export const weeklyRewardsOptions: IOptions = [
    {
        value: 0,
        label: 'INDUSTRIAL RANDOM',
        icon: '../icons/path_0.png',
    },
    {
        value: 1,
        label: 'INDUSTRIAL ORE',
        icon: '../icons/ores.png',
    },
    {
        value: 2,
        label: 'INDUSTRIAL WOOD',
        icon: '../icons/wood.png',
    },
    {
        value: 3,
        label: 'INDUSTRIAL SEED',
        icon: '../icons/seeds.png',
    },
    {
        value: 4,
        label: 'INDUSTRIAL GOLD',
        icon: '../icons/gold.png',
    },
    {
        value: 5,
        label: 'DIAMONDS',
        icon: '../icons/diamond.png',
    },
    {
        value: 6,
        label: 'CARD LEVEL RANDOM',
        icon: '../icons/card.png',
    },
    {
        value: 7,
        label: 'CARD LEVEL 1',
        icon: '../icons/card.png',
    },
    {
        value: 8,
        label: 'CARD LEVEL 2',
        icon: '../icons/card.png',
    },
    {
        value: 9,
        label: 'CARD LEVEL 3',
        icon: '../icons/card.png',
    },
    {
        value: 10,
        label: 'CARD LEVEL 4',
        icon: '../icons/card.png',
    },
    {
        value: 11,
        label: 'CARD LEVEL 5',
        icon: '../icons/card.png',
    },
    {
        value: 12,
        label: 'INTERSECTION RANDOM LVL RANDOM',
        icon: '../icons/path.png',
    },
    {
        value: 13,
        label: 'INTERSECTION RANDOM LVL 1',
        icon: '../icons/path.png',
    },
    {
        value: 14,
        label: 'INTERSECTION RANDOM LVL 2',
        icon: '../icons/path.png',
    },
    {
        value: 15,
        label: 'INTERSECTION RANDOM LVL 3',
        icon: '../icons/path.png',
    },
    {
        value: 16,
        label: 'INTERSECTION RANDOM LVL 4',
        icon: '../icons/path.png',
    },
    {
        value: 17,
        label: 'INTERSECTION 0 LVL 1',
        icon: '../icons/path_0.png',
    },
    {
        value: 18,
        label: 'INTERSECTION 0 LVL 2',
        icon: '../icons/path_0.png',
    },
    {
        value: 19,
        label: 'INTERSECTION 0 LVL 3',
        icon: '../icons/path_0.png',
    },
    {
        value: 20,
        label: 'INTERSECTION 0 LVL 4',
        icon: '../icons/path_0.png',
    },
    {
        value: 21,
        label: 'INTERSECTION 1 LVL 1',
        icon: '../icons/path_1.png',
    },
    {
        value: 22,
        label: 'INTERSECTION 1 LVL 2',
        icon: '../icons/path_1.png',
    },
    {
        value: 23,
        label: 'INTERSECTION 1 LVL 3',
        icon: '../icons/path_1`.png',
    },
    {
        value: 24,
        label: 'INTERSECTION 1 LVL 4',
        icon: '../icons/path_1`.png',
    },
    {
        value: 25,
        label: 'INTERSECTION 2 LVL 1',
        icon: '../icons/path_2.png',
    },
    {
        value: 26,
        label: 'INTERSECTION 2 LVL 2',
        icon: '../icons/path_2.png',
    },
    {
        value: 27,
        label: 'INTERSECTION 2 LVL 3',
        icon: '../icons/path_2.png',
    },
    {
        value: 28,
        label: 'INTERSECTION 2 LVL 4',
        icon: '../icons/path_2.png',
    },
    {
        value: 29,
        label: 'INTERSECTION 3 LVL 1',
        icon: '../icons/path_3.png',
    },
    {
        value: 30,
        label: 'INTERSECTION 3 LVL 2',
        icon: '../icons/path_3.png',
    },
    {
        value: 31,
        label: 'INTERSECTION 3 LVL 3',
        icon: '../icons/path_3.png',
    },
    {
        value: 32,
        label: 'INTERSECTION 3 LVL 4',
        icon: '../icons/path_3.png',
    },
    {
        value: 33,
        label: 'INTERSECTION 4 LVL 1',
        icon: '../icons/path_4.png',
    },
    {
        value: 34,
        label: 'INTERSECTION 4 LVL 2',
        icon: '../icons/path_4.png',
    },
    {
        value: 35,
        label: 'INTERSECTION 4 LVL 3',
        icon: '../icons/path_4.png',
    },
    {
        value: 36,
        label: 'INTERSECTION 4 LVL 4',
        icon: '../icons/path_4.png',
    },
    {
        value: 37,
        label: 'INTERSECTION 5 LVL 1',
        icon: '../icons/path_5.png',
    },
    {
        value: 38,
        label: 'INTERSECTION 5 LVL 2',
        icon: '../icons/path_5.png',
    },
    {
        value: 39,
        label: 'INTERSECTION 5 LVL 3',
        icon: '../icons/path_5.png',
    },
    {
        value: 40,
        label: 'INTERSECTION 5 LVL 4',
        icon: '../icons/path_5.png',
    },
    {
        value: 41,
        label: 'INTERSECTION 6 LVL 1',
        icon: '../icons/path_6.png',
    },
    {
        value: 42,
        label: 'INTERSECTION 6 LVL 2',
        icon: '../icons/path_6.png',
    },
    {
        value: 43,
        label: 'INTERSECTION 6 LVL 3',
        icon: '../icons/path_6.png',
    },
    {
        value: 44,
        label: 'INTERSECTION 6 LVL 4',
        icon: '../icons/path_6.png',
    },
    {
        value: 45,
        label: 'INTERSECTION 7 LVL 1',
        icon: '../icons/path_7.png',
    },
    {
        value: 46,
        label: 'INTERSECTION 7 LVL 2',
        icon: '../icons/path_7.png',
    },
    {
        value: 47,
        label: 'INTERSECTION 7 LVL 3',
        icon: '../icons/path_7.png',
    },
    {
        value: 48,
        label: 'INTERSECTION 7 LVL 4',
        icon: '../icons/path_7.png',
    },
    {
        value: 49,
        label: 'INTERSECTION 8 LVL 1',
        icon: '../icons/path_8.png',
    },
    {
        value: 50,
        label: 'INTERSECTION 8 LVL 2',
        icon: '../icons/path_8.png',
    },
    {
        value: 51,
        label: 'INTERSECTION 8 LVL 3',
        icon: '../icons/path_8.png',
    },
    {
        value: 52,
        label: 'INTERSECTION 8 LVL 4',
        icon: '../icons/path_8.png',
    },
    {
        value: 53,
        label: 'INTERSECTION 9 LVL 1',
        icon: '../icons/path_9.png',
    },
    {
        value: 54,
        label: 'INTERSECTION 9 LVL 2',
        icon: '../icons/path_9.png',
    },
    {
        value: 55,
        label: 'INTERSECTION 9 LVL 3',
        icon: '../icons/path_9.png',
    },
    {
        value: 56,
        label: 'INTERSECTION 9 LVL 4',
        icon: '../icons/path_9.png',
    },
    {
        value: 57,
        label: 'INTERSECTION 10 LVL 1',
        icon: '../icons/path_10.png',
    },
    {
        value: 58,
        label: 'INTERSECTION 10 LVL 2',
        icon: '../icons/path_10.png',
    },
    {
        value: 59,
        label: 'INTERSECTION 10 LVL 3',
        icon: '../icons/path_10.png',
    },
    {
        value: 60,
        label: 'INTERSECTION 10 LVL 4',
        icon: '../icons/path_10.png',
    },
    {
        value: 61,
        label: 'INTERSECTION 11 LVL 1',
        icon: '../icons/path_11.png',
    },
    {
        value: 62,
        label: 'INTERSECTION 11 LVL 2',
        icon: '../icons/path_11.png',
    },
    {
        value: 63,
        label: 'INTERSECTION 11 LVL 3',
        icon: '../icons/path_11.png',
    },
    {
        value: 64,
        label: 'INTERSECTION 11 LVL 4',
        icon: '../icons/path_11.png',
    },
];

export const weeklyRewardsLookup: any = {
    0: {
        value: 0,
        label: 'INDUSTRIAL RANDOM',
        icon: '../icons/path_0.png',
    },
    1: {
        value: 1,
        label: 'INDUSTRIAL ORE',
        icon: '../icons/ores.png',
    },
    2: {
        value: 2,
        label: 'INDUSTRIAL WOOD',
        icon: '../icons/wood.png',
    },
    3: {
        value: 3,
        label: 'INDUSTRIAL SEED',
        icon: '../icons/seeds.png',
    },
    4: {
        value: 4,
        label: 'INDUSTRIAL GOLD',
        icon: '../icons/gold.png',
    },
    5: {
        value: 5,
        label: 'DIAMONDS',
        icon: '../icons/diamond.png',
    },
    6: {
        value: 6,
        label: 'CARD LEVEL RANDOM',
        icon: '../icons/card.png',
    },
    7: {
        value: 7,
        label: 'CARD LEVEL 1',
        icon: '../icons/card.png',
    },
    8: {
        value: 8,
        label: 'CARD LEVEL 2',
        icon: '../icons/card.png',
    },
    9: {
        value: 9,
        label: 'CARD LEVEL 3',
        icon: '../icons/card.png',
    },
    10: {
        value: 10,
        label: 'CARD LEVEL 4',
        icon: '../icons/card.png',
    },
    11: {
        value: 11,
        label: 'CARD LEVEL 5',
        icon: '../icons/card.png',
    },
    12: {
        value: 12,
        label: 'INTERSECTION RANDOM LVL RANDOM',
        icon: '../icons/path.png',
    },
    13: {
        value: 13,
        label: 'INTERSECTION RANDOM LVL 1',
        icon: '../icons/path.png',
    },
    14: {
        value: 14,
        label: 'INTERSECTION RANDOM LVL 2',
        icon: '../icons/path.png',
    },
    15: {
        value: 15,
        label: 'INTERSECTION RANDOM LVL 3',
        icon: '../icons/path.png',
    },
    16: {
        value: 16,
        label: 'INTERSECTION RANDOM LVL 4',
        icon: '../icons/path.png',
    },
    17: {
        value: 17,
        label: 'INTERSECTION 0 LVL 1',
        icon: '../icons/path_0.png',
    },
    18: {
        value: 18,
        label: 'INTERSECTION 0 LVL 2',
        icon: '../icons/path_0.png',
    },
    19: {
        value: 19,
        label: 'INTERSECTION 0 LVL 3',
        icon: '../icons/path_0.png',
    },
    20: {
        value: 20,
        label: 'INTERSECTION 0 LVL 4',
        icon: '../icons/path_0.png',
    },
    21: {
        value: 21,
        label: 'INTERSECTION 1 LVL 1',
        icon: '../icons/path_1.png',
    },
    22: {
        value: 22,
        label: 'INTERSECTION 1 LVL 2',
        icon: '../icons/path_1.png',
    },
    23: {
        value: 23,
        label: 'INTERSECTION 1 LVL 3',
        icon: '../icons/path_1`.png',
    },
    24: {
        value: 24,
        label: 'INTERSECTION 1 LVL 4',
        icon: '../icons/path_1`.png',
    },
    25: {
        value: 25,
        label: 'INTERSECTION 2 LVL 1',
        icon: '../icons/path_2.png',
    },
    26: {
        value: 26,
        label: 'INTERSECTION 2 LVL 2',
        icon: '../icons/path_2.png',
    },
    27: {
        value: 27,
        label: 'INTERSECTION 2 LVL 3',
        icon: '../icons/path_2.png',
    },
    28: {
        value: 28,
        label: 'INTERSECTION 2 LVL 4',
        icon: '../icons/path_2.png',
    },
    29: {
        value: 29,
        label: 'INTERSECTION 3 LVL 1',
        icon: '../icons/path_3.png',
    },
    30: {
        value: 30,
        label: 'INTERSECTION 3 LVL 2',
        icon: '../icons/path_3.png',
    },
    31: {
        value: 31,
        label: 'INTERSECTION 3 LVL 3',
        icon: '../icons/path_3.png',
    },
    32: {
        value: 32,
        label: 'INTERSECTION 3 LVL 4',
        icon: '../icons/path_3.png',
    },
    33: {
        value: 33,
        label: 'INTERSECTION 4 LVL 1',
        icon: '../icons/path_4.png',
    },
    34: {
        value: 34,
        label: 'INTERSECTION 4 LVL 2',
        icon: '../icons/path_4.png',
    },
    35: {
        value: 35,
        label: 'INTERSECTION 4 LVL 3',
        icon: '../icons/path_4.png',
    },
    36: {
        value: 36,
        label: 'INTERSECTION 4 LVL 4',
        icon: '../icons/path_4.png',
    },
    37: {
        value: 37,
        label: 'INTERSECTION 5 LVL 1',
        icon: '../icons/path_5.png',
    },
    38: {
        value: 38,
        label: 'INTERSECTION 5 LVL 2',
        icon: '../icons/path_5.png',
    },
    39: {
        value: 39,
        label: 'INTERSECTION 5 LVL 3',
        icon: '../icons/path_5.png',
    },
    40: {
        value: 40,
        label: 'INTERSECTION 5 LVL 4',
        icon: '../icons/path_5.png',
    },
    41: {
        value: 41,
        label: 'INTERSECTION 6 LVL 1',
        icon: '../icons/path_6.png',
    },
    42: {
        value: 42,
        label: 'INTERSECTION 6 LVL 2',
        icon: '../icons/path_6.png',
    },
    43: {
        value: 43,
        label: 'INTERSECTION 6 LVL 3',
        icon: '../icons/path_6.png',
    },
    44: {
        value: 44,
        label: 'INTERSECTION 6 LVL 4',
        icon: '../icons/path_6.png',
    },
    45: {
        value: 45,
        label: 'INTERSECTION 7 LVL 1',
        icon: '../icons/path_7.png',
    },
    46: {
        value: 46,
        label: 'INTERSECTION 7 LVL 2',
        icon: '../icons/path_7.png',
    },
    47: {
        value: 47,
        label: 'INTERSECTION 7 LVL 3',
        icon: '../icons/path_7.png',
    },
    48: {
        value: 48,
        label: 'INTERSECTION 7 LVL 4',
        icon: '../icons/path_7.png',
    },
    49: {
        value: 49,
        label: 'INTERSECTION 8 LVL 1',
        icon: '../icons/path_8.png',
    },
    50: {
        value: 50,
        label: 'INTERSECTION 8 LVL 2',
        icon: '../icons/path_8.png',
    },
    51: {
        value: 51,
        label: 'INTERSECTION 8 LVL 3',
        icon: '../icons/path_8.png',
    },
    52: {
        value: 52,
        label: 'INTERSECTION 8 LVL 4',
        icon: '../icons/path_8.png',
    },
    53: {
        value: 53,
        label: 'INTERSECTION 9 LVL 1',
        icon: '../icons/path_9.png',
    },
    54: {
        value: 54,
        label: 'INTERSECTION 9 LVL 2',
        icon: '../icons/path_9.png',
    },
    55: {
        value: 55,
        label: 'INTERSECTION 9 LVL 3',
        icon: '../icons/path_9.png',
    },
    56: {
        value: 56,
        label: 'INTERSECTION 9 LVL 4',
        icon: '../icons/path_9.png',
    },
    57: {
        value: 57,
        label: 'INTERSECTION 10 LVL 1',
        icon: '../icons/path_10.png',
    },
    58: {
        value: 58,
        label: 'INTERSECTION 10 LVL 2',
        icon: '../icons/path_10.png',
    },
    59: {
        value: 59,
        label: 'INTERSECTION 10 LVL 3',
        icon: '../icons/path_10.png',
    },
    60: {
        value: 60,
        label: 'INTERSECTION 10 LVL 4',
        icon: '../icons/path_10.png',
    },
    61: {
        value: 61,
        label: 'INTERSECTION 11 LVL 1',
        icon: '../icons/path_11.png',
    },
    62: {
        value: 62,
        label: 'INTERSECTION 11 LVL 2',
        icon: '../icons/path_11.png',
    },
    63: {
        value: 63,
        label: 'INTERSECTION 11 LVL 3',
        icon: '../icons/path_11.png',
    },
    64: {
        value: 64,
        label: 'INTERSECTION 11 LVL 4',
        icon: '../icons/path_11.png',
    },
};
