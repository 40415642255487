import { BlacReact } from 'blac';
import AppStateCubit from './blocs/AppStateCubit';
import CardsCubit from './blocs/CardsCubit';
import SaveDataCubit from './blocs/SaveDataCubit';
import WeeklyRewardsCubit from './blocs/WeeklyRewardsCubit';

const state = new BlacReact([new SaveDataCubit(), new WeeklyRewardsCubit(), new CardsCubit(), new AppStateCubit()]);
export const { useBloc } = state;

export default state;
