import { Cubit } from 'blac';
import { toast } from 'react-toastify';
import { IWeeklyRewardCubit, IWeeklyReward } from '../../interfaces/interfaces';
import i18n from '../../translations/i18n';
import firebase from '../../utils/firebase';

const db = firebase.ref('/WeeklyRewards');

export default class WeeklyRewardsCubit extends Cubit<IWeeklyRewardCubit> {
    constructor() {
        super({ endAt: 55, state: [] });
    }

    public readonly getAll = async (endAt = this.state.endAt) => {
        const response = new Array<IWeeklyReward>();
        await db
            .orderByKey()
            .startAt((endAt - 4).toString())
            .endAt(endAt.toString())
            .once('value', function (snapshot: any) {
                snapshot.forEach(function (childSnapshot: any) {
                    const data = childSnapshot.val();
                    response.push({
                        ...data,
                    });
                });
            });

        if (!response) {
            this.emit({ endAt: this.state.endAt, state: [] });
        } else {
            this.emit({ endAt: this.state.endAt, state: response });
        }
    };

    public readonly setEndAt = async (value: number) => {
        this.emit({ endAt: value, state: this.state.state });
    };

    public readonly createWeeklyRewards = async (key: string, value: IWeeklyReward) => {
        await db
            .child(key)
            .set(value)
            .then((e: any) => {
                toast.success(i18n.t(`${e}`) as string);
            })
            .catch((e: any) => {
                toast.error(e);
                console.log(e);
            });
    };

    public readonly deleteWeeklyRewards = async (key: string) => {
        await db
            .child(key)
            .remove()
            .then((e: any) => {
                toast.success(i18n.t(`${e}`) as string);
            })
            .catch((e: any) => {
                toast.error(e);
                console.log(e);
            });
    };
}
