import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchByUserIdForm from '../../ui/SearchByUserIdForm';
import JsonUserEditor from '../../ui/JsonUserEditor';
import WeeklyRewardsView from '../../ui/WeeklyRewardsView';
import RoadStackView from '../../ui/RoadStackView';
import DeployedPathsView from '../../ui/DeployedPathsView';
import GameStateView from '../../ui/GameStateView';
import { useBloc } from '../../state/state';
import SaveDataCubit from '../../state/blocs/SaveDataCubit';
import UserView from '../../ui/UserView';

const Home = () => {
    const { t } = useTranslation();
    const [saveData] = useBloc(SaveDataCubit);

    // --------------------------------------------
    // UI Render
    // --------------------------------------------
    return (
        <>
            <div className="grid">
                <div className="s12 m6">
                    <SearchByUserIdForm />
                    <UserView />
                    <WeeklyRewardsView />
                    <GameStateView />
                    <RoadStackView data={saveData.json.warehouse || []} title={t('home.warehouse')} />
                    <div className="grid">
                        <div className="s12 m6">
                            <RoadStackView data={saveData.json.paidPathBoxes || []} title={t('home.paid-path-boxes')} />
                        </div>
                        <div className="s12 m6">
                            <RoadStackView data={saveData.json.givenPathBoxes || []} title={t('home.given-path-boxes')} />
                        </div>
                    </div>
                    <DeployedPathsView />
                </div>
                <div className="s12 m6">
                    <JsonUserEditor />
                </div>
            </div>
        </>
    );
};

export default Home;
