import React, { ComponentProps } from 'react';
import { IOption, IOptions } from '../../../interfaces/interfaces';

interface IProps extends ComponentProps<any> {
    id?: string;
    name?: string;
    icon?: string;
    value?: string | number;
    placeholder?: string;
    onChange?: any;
    required?: boolean;
    options: IOptions;
}

const Select = (props: IProps) => {
    const listOptions = () => {
        return (
            props.options.length > 0 &&
            props.options.map((option: IOption) => (
                <option key={option.value} value={option.value}>
                    {option.label}
                </option>
            ))
        );
    };

    const element = (
        <select
            id={props.id || ''}
            name={props.name || ''}
            value={props.value || ''}
            onChange={props.onChange || ''}
            placeholder={props.placeholder || ''}
            required={props.required || false}
            autoComplete={props.autocomplete || ''}
            aria-invalid={props.value === undefined ? true : false}
        >
            {listOptions()}
        </select>
    );

    return (
        <div className="field label prefix">
            <i>arrow_drop_down</i>
            {element}
            <label className="active">{props.label}</label>
        </div>
    );
};

export default Select;
